import React, { Component } from 'react';
import _ from 'lodash';
import Value from './Value'
import {change, Field} from 'redux-form'
import { search } from '../HelperFunctions';
import {connect} from 'react-redux';
import { dataLoad } from '../../redux/actions/autoCompleteActions';
var delayTimer

class AutoCompleteSelect extends Component {
  constructor(props) {
    super(props);
    var selectedData = {}
    if(props.data !== undefined){
      selectedData = _.find(props.data, { [props.valueField]: props.input.value });
    }
    if (props.showOnlyExistValPrefill && props.data && props.data.length === 1) {
      // only exist first {key: val} pair will be shown prefill
      this.handleSelect(props.data[0])
    }
    this.state = {
      filteredData: props.data || [],
      selectedData: selectedData || {[this.props.textField]: ''},
      searchText: '',
    };

    this.searchInput = React.createRef();

    if(props.data === undefined){
      const { klass, filter } = props
      let filters = { search: '', klass: klass, filter: filter}
      if (klass) {
        this.getData(filters)
      }
    }
  }

  handleUserSearch(value) {
    const { data, textField, klass, filter } = this.props
    this.setState({ searchText: value });
    if(data && data.length > 0)
      this.setState({ filteredData: search(data, textField, value) });
    else{
      let filters = { search: value, klass: klass, filter: filter}
     if(klass){
       clearTimeout(delayTimer);
       delayTimer = setTimeout(function () {
         this.getData(filters)
       }.bind(this), 500)
     }
    }
  }

  getData(filters){
    new Promise((resolve, reject)=>{
      this.props.dispatch(dataLoad(filters))
        .then(response=> {
          this.setState({ filteredData: response.value.data.data });
        })
      }
    )
  }

  handleSelect(data) {
    this.setState({ selectedData: data });
    const {
      props: { input, onChangeHook, valueField },
    } = this;
    input.onChange(data[valueField])
    onChangeHook && onChangeHook(data)
  }

  focusSearchInput() {
    this.searchInput.current.focus();
  }

  resetValue() {
    const { dispatch, meta, input, displayName, textField } = this.props
    dispatch(change(meta.form, input.name, null));
    dispatch(change(meta.form, displayName, null));
    this.setState({ selectedData: { [textField]: null }})
  }

  handleAddSearchVal() {
    const { addSearchValSubmit } = this.props
    addSearchValSubmit(this.searchInput?.current?.value, this.setState.bind(this))
  }

  render() {
    const { props,
      props: { input, disabled, wrapperClasses, labelClasses, displayName, textField, hideResetButton, label, hideSearch, showPrefillCorrespondValue, isNilInputValue, showAddOption },
            props: { meta: { touched, error, warning}}
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses} autocomplete`}>
        { props.label && <label className={labelClasses}>{props.label}</label> }
        <div className="dropdown border rounded autocomplete-field form-control">
          <input {...input} className={`d-none ${touched && error && 'is-invalid'}`} />
          <a
            className={`selected dropdown-toggle d-flex justify-content-between w-100`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={this.focusSearchInput.bind(this)}
          >
            <span className={`${!hideResetButton && 'mr-7'} overflow-hidden autocomplete-tooltip`}>
              {isNilInputValue && !input.value &&
                <>
                  {this.state.selectedData[textField] || <Field name={displayName || ""} component={Value} />}
                  <span className="tooltiptext">
                    {this.state.selectedData[textField] || <Field name={displayName || ""} component={Value} />}
                  </span>
                </>
              }
              {input.value && <>
                {showPrefillCorrespondValue ?
                  <>
                    <Field name={displayName || ""} component={Value} />
                    <span className="tooltiptext">
                      <Field name={displayName || ""} component={Value} />
                    </span>
                  </> :
                  <>
                    {this.state.selectedData[textField] || <Field name={displayName || ""} component={Value} />}
                    <span className="tooltiptext">
                      {this.state.selectedData[textField] || <Field name={displayName || ""} component={Value} />}
                    </span>
                  </>
                }
              </>}
            </span>
            <span className="la la-angle-down text-right text-muted" />
          </a>
          { !hideResetButton && input.value && <span className="la la-times-circle text-right text-muted reset-close pointer" onClick={() => this.resetValue()}/> }

          <div className={`dropdown-menu dropdown-search w-100`} style={{maxHeight: "30rem"}}>
            <div className={`p-5 d-flex align-items-center ${ hideSearch && 'd-none' }`}>
              <input
                ref={this.searchInput}
                className="form-control w-100"
                placeholder={`${showAddOption ? 'Search to add' :'Search...'}`}
                onChange={(e)=> this.handleUserSearch(e.target.value)}
              />
              {(this.props.showAddOption && this.state.searchText) ?
                <button type='button' className='btn btn-primary btn-sm font-size-12 py-1 px-3 ml-2' onClick={() => this.handleAddSearchVal()}>Add</button> : ''}
            </div>
            {this.renderRecords()}
          </div>
        </div>
        {
          touched &&
          ((error && <span className="error-msg text-danger d-inline la-sm">{error}</span>) ||
          (warning && <span>{warning}</span>))
        }
        {
          touched && (error && <i className={`la la-warning text-danger ${label === undefined ? 'error-icon' : 'error-icon-label'}`}></i>)
        }
      </div>
    );
  }

  renderRecords(){
    if(this.state.filteredData.length > 0)
      return(
        <div className="overflow-auto h-50">
          {this.state.filteredData.map(data => (
            <div className="dropdown-item d-flex align-items-center" key={data[this.props.textField]} onClick={this.handleSelect.bind(this, data)}>
              <span className="py-2 text-wrap">{data[this.props.textField]}</span>
            </div>
          ))}
        </div>
      )
    else if(this.searchInput.current && this.searchInput.current.value !== "")
      return(
        <div className="overflow-auto h-50 text-center">
          <p className="mb-3">No record found</p>
        </div>
      )
  }
}

export default connect()(AutoCompleteSelect);
