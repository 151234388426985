import React, { useState, useRef, useEffect } from 'react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { defaultStaticRanges, defaultInputRanges } from 'react-date-range';
import { DateRangePicker as DateRangePickerPlugin } from 'react-date-range';

import Moment from 'moment'
import { useDispatch } from 'react-redux';
import { dateRangePickerDropdown } from '../../redux/actions/filterActions';
import { getCurrentThemeColor } from '../HelperFunctions';

import {
  startOfYear, endOfYear, addYears,
  isSameDay,
  startOfQuarter,
  endOfQuarter,
  subQuarters
} from "date-fns";

const isSelected = (rangeFn, range) => {
  const definedRange = rangeFn();
  return (
    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
  );
};
// rangeFn contains range defined for the label & range contains date range selected in calender.
const customStaticRanges = [
  ...defaultStaticRanges,
  {
    label: "This Year",
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date())
    }),
    isSelected(range) { isSelected(this.range, range) }
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1))
    }),
    isSelected(range) { isSelected(this.range, range) }

  },

  {
    label: "This Quarter",
    range: () => ({
      startDate: startOfQuarter(new Date()),
      endDate: endOfQuarter(new Date())
    }),
    isSelected(range) { isSelected(this.range, range) }
  },
  {
    label: "Last Quarter",
    range: () => ({
      startDate: startOfQuarter(subQuarters(new Date(), 1)),
      endDate: endOfQuarter(subQuarters(new Date(), 1))
    }),
    isSelected(range) { isSelected(this.range, range) }
  },

]

export default function DateRangePicker(props) {
  const { onFilterChange, dateRangePicker, filters: { filter } } = props

  const [selectedDate, setSelectedDate] = useState({
    startDate: filter?.date_filter ? new Date(filter.date_filter.from) : new Date(),
    endDate: filter?.date_filter ? new Date(filter.date_filter.to) : new Date(),
    key: 'selection'
  })
  const dropdownRef = useRef(null)
  const dispatch = useDispatch()

  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dispatch(dateRangePickerDropdown(false))
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDateChange = (data) => {
    let filter = { filter: { date_filter: { from: Moment(data.selection.startDate).format("YYYY/MM/DD"), to: Moment(data.selection.endDate).format("YYYY/MM/DD") } } }
    onFilterChange(filter)
    setSelectedDate(data.selection)
  }
  return (
    <div className='d-flex align-items-end width-16' ref={dropdownRef}>
      <div className='d-flex align-items-center ml-5 '>
        <div className="d-flex flex-column">
          <span>From</span>
          <input className='filter-date bg-white form-control form-control-sm pointer' value={filter?.date_filter ? Moment(filter.date_filter.from).format("MM/DD/YYYY") : "MM/DD/YYYY"} onClick={() => dispatch(dateRangePickerDropdown(true))} readOnly></input>
        </div>
        <div className="d-flex flex-column">
          <span>To</span>
          <input className='filter-date bg-white form-control form-control-sm border-left-0 pointer' value={filter?.date_filter ? Moment(filter.date_filter.to).format("MM/DD/YYYY") : "MM/DD/YYYY"} onClick={() => dispatch(dateRangePickerDropdown(true))} readOnly></input>
        </div>
      </div>
      <button class=" font-size-18 bg-white border border-left-0 position-relative  "  >
        <i class="las la-calendar" onClick={() => dispatch(dateRangePickerDropdown(!dateRangePicker))}></i>
        {dateRangePicker && <div className='position-absolute mt-3 shadow-lg position-date-range-picker ' >
          <DateRangePickerPlugin
            ranges={[selectedDate]}
            onChange={(data) => handleDateChange(data)}
            rangeColors={[getCurrentThemeColor()]}
            staticRanges={customStaticRanges}
            maxDate={new Date()}
            inputRanges={[{
              ...defaultInputRanges[0],
              label: 'past days'
            }]}
          />
        </div>
        }
      </button>
    </div>
  )
}
