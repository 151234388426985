import React from "react";
import * as $ from 'jquery'

export const FilePreview = (props) => {
  const { id, imageUrl, label } = props

  const modalClose = () => {
    $(`#file-preview-${id}`).modal('hide')
  }

  return (
    <div className="modal fade" id={`file-preview-${id}`} style={{zIndex: 999999}} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" style={{minWidth: "auto"}} role="document">
        <div className="modal-content">
          <div className="modal-header py-5">
            <h5 className="modal-title" id="exampleModalCenterTitle">{label}</h5>
            <div className="d-flex">
              <a href={imageUrl} download={true} className="btn btn-outline-primary mx-6">Download</a>
              <button type="button" className="close py-5" onClick={modalClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="modal-body">
            <embed style={{ width: "100%", maxHeight: '700px', objectFit: 'fit' }} src={imageUrl} alt="original"></embed>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
